import React, { useContext, useState } from "react";
import ErrorBoundary from "./ErrorBoundary";
import { IAuditExtended, IBaseQueryResult, IOperationRequest, ISyncTask, SyncType, TaskStatus, syncPermission } from "@symity-hub/types";
import { Divider, Link, Menu, MenuButtonProps, MenuItem, MenuList, MenuPopover, MenuTrigger, Spinner, SplitButton, Text, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { ArrowSync24Regular, ArrowSyncDismiss24Regular } from "@fluentui/react-icons";
import { RbacWrapper } from "./RbacWrapper";
import { useMsal } from "@azure/msal-react";
import useSWR from "swr";
import { fetcher } from "../modules/api";
import { AppContext } from "../contexts/AppContext";
import { DateTime } from "luxon";
import { sharedHorizontalExtraSmallGapFlexStyles } from "../styles/styles";
import { StatusIcon } from "./StatusIcon";
import { AuditPanel } from "./AuditPanel";

const syncStyles = makeStyles({
    root: {
        ...shorthands.padding(tokens.spacingHorizontalS, tokens.spacingVerticalS)
    },
    syncButton: {
        marginLeft: "auto"
    }
});

const appAlertName = "syncOutOfDate";

export const Sync: React.FunctionComponent = () => {

    const msalContext = useMsal();
    const appContext = useContext(AppContext);

    const [syncInProgress, setSyncInProgress] = useState<boolean>(true);
    const [syncOutOfDate, setSyncOutOfDate] = useState<boolean>(false);
    const [syncData, setSyncData] = useState<IAuditExtended>();
    const [panelOpen, setPanelOpen] = useState<boolean>(false);

    const { isLoading } = useSWR<IBaseQueryResult>(["/api/operations/sync/latest", msalContext], fetcher,
        {
            revalidateOnFocus: true,
            revalidateIfStale: true,
            // If sync in progress, refresh every 3 seconds, otherwise every 2 minutes
            refreshInterval: syncInProgress ? 3000 : 120000,
            onSuccess: (data) => {
                if (data && data.resources.length > 0) {
                    const latest = data.resources[0] as IAuditExtended;
                    // Check if the latest sync is out of date (older than 24 hours)
                    if (DateTime.fromISO(latest.operation?.lastUpdatedTime as unknown as string).diffNow("hours").hours < -24) {
                        setSyncOutOfDate(true);
                        if (!appContext.alerts.some(alert => alert.id === appAlertName)) {
                            appContext.setAlerts([...appContext.alerts, {
                                id: appAlertName,
                                text: "Sync is out of date. Please initiate a new sync to ensure information is up-to-date.",
                                type: TaskStatus.Warning
                            }]);
                        }
                    } else {
                        appContext.setAlerts(appContext.alerts.filter((alert) => alert.id !== appAlertName));
                        setSyncOutOfDate(false);
                    }
                    // Check if sync is in progress
                    if (latest.operation?.runtimeStatus === "Running" || latest.operation?.runtimeStatus === "Pending") {
                        setSyncInProgress(true);
                    } else {
                        setSyncInProgress(false);
                    }
                    // Set the latest sync data
                    setSyncData(latest);
                } else {
                    setSyncInProgress(false);
                }
            }
        }
    );

    const onSyncClick = async (syncType: SyncType) => {
        if (syncInProgress && syncData && syncData.operation) {
            await fetcher([`/api/operations/${syncData.operation.instanceId}/terminate`, msalContext, "POST"])
        } else {
            const tasks: ISyncTask[] = [
                {
                    name: "syncGraphUsers",
                    syncType
                },
                {
                    name: "syncTeamsPhoneNumberAssignments",
                    syncType: SyncType.Full
                }
            ];
            const operationRequest: IOperationRequest = {
                tasks
            };
            await fetcher(["/api/operations/sync", msalContext, "POST", undefined, operationRequest])
                .then(() => {
                    // Delay setting the sync in progress to allow the sync to start in the backend
                    setTimeout(() => {
                        setSyncInProgress(true);
                    }, 1500);
                    appContext.notify("Sync started", "success");
                })
                .catch((error) => {
                    console.error(error);
                    appContext.notify("Error starting sync, please try again.", "error");
                });
        }
    }

    const onDismiss = () => {
        setPanelOpen(false);
    }

    const primaryActionButtonProps = {
        onClick: () => onSyncClick(SyncType.Delta)
    };

    return (
        <ErrorBoundary>
            <Divider />
            <div
                className={syncStyles().root}
            >
                {isLoading
                    ? <Spinner
                        size="extra-small"
                        label="Getting sync status..."
                    />
                    : (
                        <div>
                            <div
                                className={sharedHorizontalExtraSmallGapFlexStyles().root}
                            >
                                <StatusIcon status={
                                    syncOutOfDate
                                        ? TaskStatus.Warning
                                        : syncData?.operation?.runtimeStatus as string
                                }
                                />
                                <Link
                                    onClick={() => setPanelOpen(true)}
                                >
                                    <Text size={400}>{
                                        syncInProgress
                                            ? "Syncing"
                                            : "Last sync"
                                    }
                                    </Text>
                                </Link>
                                <div
                                    className={syncStyles().syncButton}
                                >
                                    <RbacWrapper allowedPermissions={[syncPermission]}>
                                        <Menu positioning="below-end">
                                            <MenuTrigger disableButtonEnhancement>
                                                {(triggerProps: MenuButtonProps) => (
                                                    <SplitButton
                                                        menuButton={triggerProps}
                                                        primaryActionButton={primaryActionButtonProps}
                                                        size="small"
                                                        icon={syncInProgress ? <ArrowSyncDismiss24Regular /> : <ArrowSync24Regular />}
                                                    >
                                                        {syncInProgress
                                                            ? "Stop"
                                                            : "Sync"
                                                        }
                                                    </SplitButton>
                                                )}
                                            </MenuTrigger>
                                            <MenuPopover>
                                                <MenuList>
                                                    <MenuItem
                                                        onClick={() => onSyncClick(SyncType.Full)}
                                                    >Full sync</MenuItem>
                                                </MenuList>
                                            </MenuPopover>
                                        </Menu>
                                    </RbacWrapper>
                                </div>
                            </div>
                            <Text>
                                {syncData && syncData.operation
                                    ? syncInProgress
                                        ? `${syncData.operation.runtimeStatus as string}, Started: ${DateTime.fromISO(syncData.operation.createdTime as unknown as string).toRelative()}`
                                        : `${syncData.operation.runtimeStatus}, ${DateTime.fromISO(syncData.operation.lastUpdatedTime as unknown as string).toRelative()}`
                                    : "No sync information available"
                                }
                            </Text>
                        </div>
                    )}
            </div>
            {panelOpen && syncData && (
                <AuditPanel
                    open={panelOpen}
                    setOpen={setPanelOpen}
                    data={syncData}
                    onDismiss={onDismiss}
                />
            )}
        </ErrorBoundary>
    );
}
