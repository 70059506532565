import React from "react";
import { CustomDrawer } from "./CustomDrawer";
import ErrorBoundary from "./ErrorBoundary";
import { IAudit, IBaseQueryResult, TaskStatus } from "@symity-hub/types";
import { Spinner, Subtitle2, Text, makeStyles } from "@fluentui/react-components";
import { sharedHorizontalExtraSmallGapFlexStyles, sharedHorizontalNoGapFlexStyles, sharedVerticalMediumGapFlexStyles } from "../styles/styles";
import { StatusIcon } from "./StatusIcon";
import { DateTime } from "luxon";
import useSWR from "swr";
import { fetcher } from "../modules/api";
import { useMsal } from "@azure/msal-react";
import { CustomAlert } from "./CustomAlert";
import { Section } from "./Section";

interface INotificationUserPanelProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const notificationUserPanelStyles = makeStyles({
  date: {
    marginLeft: "auto",
  }
});

export const NotificationUserPanel: React.FunctionComponent<INotificationUserPanelProps> = (props) => {

  const msalContext = useMsal();

  const { data, error, isLoading } = useSWR<IBaseQueryResult>(props.open ? [`/api/me/audit`, msalContext, "GET"] : null, fetcher,
    {
      refreshInterval: 2000,
    }
  );

  const onDismiss = () => {
    props.setOpen(false);
  }

  return (
    <CustomDrawer
      open={props.open}
      onDismiss={onDismiss}
      headerText="Notifications"
      size="medium"
    >
      <ErrorBoundary>
        {error && (<CustomAlert
          text={error}
          type={TaskStatus.Error}
          id="notificationUserPanel"
        />)}
        {isLoading && <Spinner size="large" />}
        {data && data.resources.length &&
          <div
            className={sharedVerticalMediumGapFlexStyles().root}
          >
            {data.resources.map((audit: IAudit) => (
              <Section
                key={audit.id}
                title={(
                  <div className={sharedHorizontalExtraSmallGapFlexStyles().root}>
                    <StatusIcon status={audit.status} />
                    <Subtitle2>{audit.description}</Subtitle2>
                  </div>
                )}
                description={audit.status}
              >
                <div
                  className={sharedHorizontalNoGapFlexStyles().root}
                >
                  <Text
                    className={notificationUserPanelStyles().date}
                    size={200}
                    italic
                  >
                    {DateTime.fromISO(audit.date).toRelative()}
                  </Text>
                </div>
              </Section>
            ))}
          </div>
        }
      </ErrorBoundary>
    </CustomDrawer>
  );
}
