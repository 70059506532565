import React, { useState } from 'react';
import { AppInsightsContext, ReactPlugin, withAITracking } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { AppHeader } from "./components/AppHeader";
import { MainView } from "./views/MainView";
import { FluentProvider, Theme, makeStyles } from "@fluentui/react-components";
import { BrowserRouter } from "react-router-dom";
import { symityDarkTheme, symityLightTheme } from "./styles/theme";
import { ThemeContext } from "./contexts/ThemeContext";
import { SignedInUserContext } from "./contexts/SignedInUserContext";
import { IUserWithEffectivePermissions } from "@symity-hub/types";


const reactPlugin = new ReactPlugin();

const appStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    flexGrow: 1
  }
});

if (process.env.REACT_APP_APPLICATIONINSIGHTS_CONNECTION_STRING) {
  const appInsights = new ApplicationInsights({
    config: {
      connectionString: process.env.REACT_APP_APPLICATIONINSIGHTS_CONNECTION_STRING,
      enableAutoRouteTracking: true,
      extensions: [reactPlugin]
    }
  });
  appInsights.loadAppInsights();
}

export const App: React.FunctionComponent = () => {

  const localStorageTheme = localStorage.getItem("theme");
  const [theme, setTheme] = useState<Theme>(localStorageTheme === "dark" ? symityDarkTheme : symityLightTheme);
  const [signedInUser, setSignedInUser] = useState<IUserWithEffectivePermissions>();

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <FluentProvider theme={theme}>
        <AppInsightsContext.Provider value={reactPlugin}>
          <SignedInUserContext.Provider value={{
            signedInUser,
            setSignedInUser
          }}>
            <BrowserRouter>
              <div className={appStyles().root}>
                <AppHeader />
                <MainView />
              </div>
            </BrowserRouter>
          </SignedInUserContext.Provider>
        </AppInsightsContext.Provider>
      </FluentProvider>
    </ThemeContext.Provider>
  );
}

export default withAITracking(reactPlugin, App);
