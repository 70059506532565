import { z } from "zod";
import { e164Regex } from "../validation/base";
import { PhoneNumberClassification } from "./enums/PhoneNumberClassification";
import { PhoneNumberType } from "./enums/PhoneNumberType";
import { PstnAssignmentStatus } from "./enums/PstnAssignmentStatus";
import { baseCosmosItemSchema } from "./IBaseCosmosItem";
import { FeedbackType } from "./enums/FeedbackType";

export const phoneNumberSchema = baseCosmosItemSchema.extend({
    phoneNumber: z.string({ required_error: "Phone number is required" })
        .regex(e164Regex, "Phone number must be in E.164 format"),
    extensionNumber: z.string()
        .optional(),
    classification: z.nativeEnum(PhoneNumberClassification, { required_error: "Classification is required" }),
    numberType: z.nativeEnum(PhoneNumberType, { required_error: "Number type is required" }),
    activationState: z.string()
        .optional(),
    userId: z.string()
        .uuid()
        .optional(),
    rangeId: z.string()
        .uuid()
        .optional(),
    pstnAssignmentStatus: z.nativeEnum(PstnAssignmentStatus, { required_error: "PSTN assignment status is required" }),
    notes: z.string()
        .optional()
})
    .strict();

export type IPhoneNumber = z.infer<typeof phoneNumberSchema>;

export interface IPhoneNumberExtended extends IPhoneNumber {
    userDisplayName?: string;
    rangeName?: string;
}