import React, { useState } from "react";
import ErrorBoundary from "./ErrorBoundary";
import { IOperationStatus, IOperationTask, TaskStatus } from "@symity-hub/types";
import useSWR from "swr";
import { fetcher } from "../modules/api";
import { useMsal } from "@azure/msal-react";
import { CustomAlert } from "./CustomAlert";
import { sharedHorizontalExtraSmallGapFlexStyles, sharedHorizontalMediumGapFlexStyles } from "../styles/styles";
import { Button, Caption1, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Divider, Spinner, Text, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";
import { StatusIcon } from "./StatusIcon";

interface IOperationDialogProps {
    open: boolean;
    onDismiss: () => void
    operationId?: string;
}

const operationDialogStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        ...shorthands.gap(tokens.spacingHorizontalS, tokens.spacingVerticalS),
        ...shorthands.padding(0, 0, tokens.spacingVerticalM, 0)
    },
    item: {
        display: "flex",
        flexDirection: "column",
        ...shorthands.gap(tokens.spacingHorizontalXS),
        ...shorthands.padding(tokens.spacingVerticalXS, 0)
    },
});


export const OperationDialog: React.FunctionComponent<IOperationDialogProps> = (props) => {

    const msalContext = useMsal();

    const [operationComplete, setOperationComplete] = useState<boolean>(false);
    const [operationStatus, setOperationStatus] = useState<IOperationStatus>();

    // Get operation status
    const { error } = useSWR<IOperationStatus>(props.operationId && !operationComplete ? [`/api/operations/${props.operationId}`, msalContext, "GET"] : null, fetcher, {
        refreshInterval: 2000,
        onSuccess: (data) => {
            if (data) {
                setOperationStatus(data);
                if (data.runtimeStatus !== "Running" && data.runtimeStatus !== "Pending") {
                    setOperationComplete(true);
                } else {
                    setOperationComplete(false);
                }
            }
        }
    });

    return (
        <Dialog
            open={props.open}
            onOpenChange={() => props.onDismiss()}
        >
            <DialogSurface>
                <DialogBody>
                    <DialogTitle
                        action={
                            <DialogTrigger action="close">
                                <Button
                                    appearance="subtle"
                                    aria-label="close"
                                    icon={<Dismiss24Regular />}
                                />
                            </DialogTrigger>
                        }
                    >
                        <div className={sharedHorizontalMediumGapFlexStyles().root}>
                            {// With errors?
                                operationStatus?.output?.tasks && operationStatus.output.tasks.some((task: IOperationTask) => task.status === TaskStatus.Failed) && (
                                    <StatusIcon status={TaskStatus.Failed} />
                                )}
                            {// With success?
                                operationStatus?.output?.tasks && operationStatus.output.tasks.every((task: IOperationTask) => task.status === TaskStatus.Completed) && (
                                    <StatusIcon status={TaskStatus.Completed} />
                                )}
                            {operationStatus?.runtimeStatus || "Working on it"}
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        <ErrorBoundary>
                            <div className={operationDialogStyles().root}>
                                <Caption1>Please note: changes may take some time to take effect.</Caption1>
                                {error && <CustomAlert
                                    text={error}
                                    type={TaskStatus.Error}
                                    id="operationDialog"
                                />}
                                {!operationComplete && <Spinner size="large" />}
                                <div>
                                    {operationStatus?.output?.tasks && operationStatus.output.tasks.length > 0 && operationStatus.output.tasks.map((task: IOperationTask, index: number) => {
                                        return (
                                            <div key={index}>
                                                <Divider />
                                                <div className={operationDialogStyles().item}>
                                                    {task.status === TaskStatus.Failed ? (
                                                        <div className={sharedHorizontalExtraSmallGapFlexStyles().root}>
                                                            <StatusIcon status={TaskStatus.Failed} />
                                                            <Text size={200}>{task.name} failed: {task.errors?.join(", ")}</Text>
                                                        </div>
                                                    )
                                                        : task.status && (
                                                            <div className={sharedHorizontalExtraSmallGapFlexStyles().root}>
                                                                <StatusIcon status={task.status} />
                                                                <Text size={200}>{task.name}</Text>
                                                            </div>
                                                        )}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <DialogActions>
                                <DialogTrigger action="close">
                                    <Button
                                        appearance="secondary"
                                        aria-label="close"
                                    >
                                        Close
                                    </Button>
                                </DialogTrigger>
                            </DialogActions>
                        </ErrorBoundary>
                    </DialogContent>
                </DialogBody>
            </DialogSurface>
        </Dialog >
    );
}