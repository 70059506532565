import React, { useEffect, useState } from "react";
import ErrorBoundary from "./ErrorBoundary";
import { User } from "@microsoft/microsoft-graph-types";
import { IBaseQueryParams, IOperationRequest, IPhoneNumber, IPhoneNumberAssignmentTask, PhoneNumberAssignmentAction, PhoneNumberClassification, phoneNumberAssignmentTaskSchema, PstnAssignmentStatus, PhoneNumberType, IBaseQueryResult, TaskStatus } from "@symity-hub/types";
import { sharedVerticalMediumGapFlexStyles } from "../styles/styles";
import { fetcher } from "../modules/api";
import { useMsal } from "@azure/msal-react";
import useSWR from "swr";
import { ChoosePhoneNumber } from "./ChoosePhoneNumber";
import { CustomAlert } from "./CustomAlert";
import { OperationDialog } from "./OperationDialog";
import { getOptionsFromEnum } from "../modules/enum";
import { IQueryProps } from "../types/IQueryProps";
import { IDropdownFilter } from "../types/IDropdownFilter";
import { renderPhoneNumberAssignmentText, renderPhoneNumberWithExtension } from "../modules/string";
import { CustomEditDrawer } from "./CustomEditDrawer";
import { TableColumnDefinition, createTableColumn, TableCellLayout, Button, Spinner } from "@fluentui/react-components";
import { BasicDataGrid } from "./BasicDataGrid";
import { Edit24Regular } from "@fluentui/react-icons";
import { ZodError } from "zod";
import { populateDropdownFilter } from "./DropdownFilters";

interface IAssignPhoneNumberPanelProps {
    users: User[];
    open: boolean;
    setOpen: (open: boolean) => void;
    onDismiss: () => void
}

export const AssignPhoneNumberPanel: React.FunctionComponent<IAssignPhoneNumberPanelProps> = (props) => {

    const msalContext = useMsal();

    const [phoneNumberAssignments, setPhoneNumberAssignments] = useState<IPhoneNumberAssignmentTask[]>([]);
    const [userToAssign, setUserToAssign] = useState<User | undefined>(undefined);
    const [operationId, setOperationId] = useState<string>();
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [validationError, setValidationError] = useState<ZodError>();

    // Store query params outside of choose number component so it persists between user selection
    const defaultQueryParams: IBaseQueryParams = {
        sortBy: "phoneNumber",
        limit: 8,
        filters: [
            {
                name: "pstnAssignmentStatus",
                operator: "eq",
                value: PstnAssignmentStatus.Unassigned
            },
            {
                name: "classification",
                operator: "eq",
                value: PhoneNumberClassification.Standard
            }
        ]
    };

    const defaultDropdownFilters: IDropdownFilter[] = [
        {
            name: "rangeId",
            label: "Phone number range",
            options: [],
            selectedKeys: [],
            multiSelect: true
        },
        {
            name: "classification",
            label: "Classification",
            options: getOptionsFromEnum(PhoneNumberClassification),
            selectedKeys: [
                PhoneNumberClassification.Standard
            ],
            multiSelect: true
        },
        {
            name: "numberType",
            label: "Type",
            options: getOptionsFromEnum(PhoneNumberType),
            selectedKeys: [],
            multiSelect: true
        }
    ];

    const [queryProps, setQueryProps] = useState<IQueryProps>({
        params: defaultQueryParams,
        searchField: "phoneNumber",
        searchPlaceholderText: "Search by phone number"
    });

    const [dropdownFilters, setDropdownFilters] = useState<IDropdownFilter[]>(defaultDropdownFilters);

    // Get any existing phone number assignments
    const { data, error, mutate, isLoading } = useSWR<IPhoneNumber[]>(props.users.length > 0 && props.open ? [`/api/voice/phoneNumbers/users/assigned`, msalContext, "POST", undefined, { userIds: props.users.map((user) => user.id) }] : null, fetcher,
        {
            revalidateIfStale: false,
            revalidateOnFocus: false,
            onSuccess: (data) => {
                // Filter on user phone numbers that have assigned pstn target id set
                setPhoneNumberAssignments(data.filter((item) => item.userId).map((phoneNumber) => {
                    return {
                        name: renderPhoneNumberAssignmentText(PhoneNumberAssignmentAction.Assign, phoneNumber.phoneNumber, phoneNumber.extensionNumber, props.users.find((user) => user.id === phoneNumber.userId)?.displayName || ""),
                        phoneNumber: phoneNumber.phoneNumber,
                        extensionNumber: phoneNumber.extensionNumber,
                        userId: phoneNumber.userId!,
                        phoneNumberType: phoneNumber.numberType,
                        action: PhoneNumberAssignmentAction.Assign
                    }
                }));
            }
        }
    );

    useSWR<IBaseQueryResult>([`/api/voice/phoneNumberRanges`, msalContext, undefined, {
        sortBy: "name",
        select: ["id", "name"],
        limit: 100,
    }], fetcher, {
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        onSuccess: (data) => {
            populateDropdownFilter(data, dropdownFilters, setDropdownFilters, "rangeId", "id", "name");
        }
    });

    const onSave = async () => {
        if (phoneNumberAssignments.length > 0) {
            // Validate schema
            const validAssignments = await Promise.all(phoneNumberAssignments.map(async (assignment) => {
                return await phoneNumberAssignmentTaskSchema.parseAsync(assignment)
                    .then(async () => {
                        return assignment;
                    })
            })).catch((error: ZodError) => {
                console.error(error.errors);
                setValidationError(error);
            });
            if (validAssignments && validAssignments.length > 0 && !validationError) {
                // Filter out unassign actions if user has an assign action (it overwrites the unassign)
                const filteredAssignments = validAssignments.filter((assignment) => {
                    if (assignment?.action === PhoneNumberAssignmentAction.Unassign) {
                        return !validAssignments.some((a) => a?.userId === assignment?.userId && a?.action === PhoneNumberAssignmentAction.Assign);
                    }
                    return true;
                });
                if (filteredAssignments.length > 0) {
                    // Create operation request
                    const operationRequest: IOperationRequest = {
                        tasks: filteredAssignments
                    }
                    // Send request
                    await fetcher(["/api/voice/phoneNumbers/assign", msalContext, "POST", undefined, operationRequest])
                        .then((data) => {
                            // If successful, set the operation ID and open the dialog
                            if (data.operationId) {
                                setOperationId(data.operationId);
                                setDialogOpen(true);
                            }
                        })
                }
            }
        }
    }

    // Refresh data when panel is opened
    useEffect(() => {
        if (props.open) {
            mutate();
        }
    }, [mutate, props.open]);

    const onDismiss = () => {
        // Reset state
        setPhoneNumberAssignments([]);
        setUserToAssign(undefined);
        setQueryProps({
            ...queryProps,
            params: defaultQueryParams
        });
        setDropdownFilters(defaultDropdownFilters);
        props.onDismiss();
    }

    const onDismissOperationDialog = () => {
        setDialogOpen(false);
        setOperationId(undefined);
        onDismiss();
    }

    // Calculate which numbers require an unassign action
    useEffect(() => {
        // Calculate numbers in data but not against assignment of users
        const numbersInDataNotInAssignments = data?.filter((item) => item.userId).map((item) => item.phoneNumber).filter((item) => !phoneNumberAssignments.map((item) => item.phoneNumber).includes(item));
        const itemsInDataNotInAssignments = data?.filter((item) => numbersInDataNotInAssignments?.includes(item.phoneNumber));
        // Add missing items to unassign assignments
        if (itemsInDataNotInAssignments && itemsInDataNotInAssignments.length > 0) {
            setPhoneNumberAssignments([...phoneNumberAssignments, ...itemsInDataNotInAssignments.map((phoneNumber) => {
                return {
                    name: renderPhoneNumberAssignmentText(PhoneNumberAssignmentAction.Unassign, phoneNumber.phoneNumber, phoneNumber.extensionNumber, props.users.find((user) => user.id === phoneNumber.userId)?.displayName || ""),
                    phoneNumber: phoneNumber.phoneNumber,
                    extensionNumber: phoneNumber.extensionNumber,
                    userId: phoneNumber.userId!,
                    phoneNumberType: phoneNumber.numberType,
                    action: PhoneNumberAssignmentAction.Unassign
                }
            })]);
        }
    }, [data, phoneNumberAssignments, props.users]);

    const columns: TableColumnDefinition<User>[] = [
        createTableColumn<User>({
            columnId: "displayName",
            renderHeaderCell: () => {
                return "Name";
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout>
                        {item.displayName}
                    </TableCellLayout>
                );
            }
        }),
        createTableColumn<User>({
            columnId: "phoneNumber",
            renderHeaderCell: () => {
                return "Phone number";
            },
            renderCell: (item) => {
                const assignment = phoneNumberAssignments.find(assignment => assignment.userId === item.id && assignment.action !== PhoneNumberAssignmentAction.Unassign);
                return (
                    <TableCellLayout>
                        {assignment?.phoneNumber ? renderPhoneNumberWithExtension(assignment.phoneNumber, assignment.extensionNumber) : "No phone number assigned"}
                    </TableCellLayout>
                );
            }
        }),
        createTableColumn<User>({
            columnId: "phoneNumberType",
            renderHeaderCell: () => {
                return "Phone number type";
            },
            renderCell: (item) => {
                const assignment = phoneNumberAssignments.find(assignment => assignment.userId === item.id && assignment.action !== PhoneNumberAssignmentAction.Unassign);
                return (
                    <TableCellLayout>
                        {assignment?.phoneNumberType ? assignment.phoneNumberType : ""}
                    </TableCellLayout>
                );
            }
        }),
        createTableColumn<User>({
            columnId: "actions",
            renderHeaderCell: () => {
                return "";
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout>
                        <Button
                            icon={<Edit24Regular />}
                            onClick={() => { setUserToAssign(item) }}
                        >
                            Edit
                        </Button>
                    </TableCellLayout>
                );
            }
        })
    ];

    return (
        <CustomEditDrawer
            open={props.open}
            onDismiss={onDismiss}
            onSave={onSave}
            headerText="Assign phone numbers"
            validationError={validationError}
            size="large"
        >
            <ErrorBoundary>
                <div className={sharedVerticalMediumGapFlexStyles().root}>
                    {error && (<CustomAlert text={error} type={TaskStatus.Error} id= "assignPhoneNumberPanel"/>)}
                    {isLoading
                        ? <Spinner size="large" />
                        : data && props.users.length > 0 && !userToAssign && (
                            <BasicDataGrid
                                columns={columns}
                                items={props.users}
                            />
                        )}
                    {userToAssign && (
                        <ChoosePhoneNumber
                            queryProps={queryProps}
                            setQueryProps={setQueryProps}
                            dropdownFilters={dropdownFilters}
                            setDropdownFilters={setDropdownFilters}
                            phoneNumberAssignments={phoneNumberAssignments}
                            setPhoneNumberAssignments={setPhoneNumberAssignments}
                            userToAssign={userToAssign}
                            setUserToAssign={setUserToAssign}
                        />
                    )}
                    <OperationDialog
                        open={dialogOpen}
                        onDismiss={onDismissOperationDialog}
                        operationId={operationId} />
                </div>
            </ErrorBoundary>
        </CustomEditDrawer>
    );
};
