import React, { useContext, useState } from "react";
import ErrorBoundary from "../components/ErrorBoundary";
import { BreadcrumbMenu } from "../components/BreadcrumbMenu";
import { ContentRoutes } from "../components/ContentRoutes";
import { AppContext } from "../contexts/AppContext";
import { IUserWithEffectivePermissions, TaskStatus } from "@symity-hub/types";
import { useMsal } from "@azure/msal-react";
import { fetcher } from "../modules/api";
import useSWR from "swr";
import { Link, Text, Toast, ToastIntent, ToastTitle, Toaster, makeStyles, shorthands, tokens, useId, useToastController } from "@fluentui/react-components";
import { NavMenu } from "../components/NavMenu";
import { AcceptDialog } from "../components/AcceptDialog";
import { CustomAlert, ICustomAlertProps } from "../components/CustomAlert";
import { AppAlerts } from "../components/AppAlerts";
import { SignedInUserContext } from "../contexts/SignedInUserContext";

const authenticatedContentStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "row",
        flexGrow: 1,
        backgroundColor: tokens.colorNeutralBackground5
    },
    content: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        flexGrow: 1,
        ...shorthands.margin(0, tokens.spacingHorizontalM, tokens.spacingVerticalM, tokens.spacingHorizontalM)
    },
    signInError: {
        ...shorthands.padding(0, tokens.spacingHorizontalM)
    }
});

export const AuthenticatedContent: React.FunctionComponent = () => {

    const msalContext = useMsal();
    const signedInUserContext = useContext(SignedInUserContext);

    const [pageName, setPageName] = useState<{ originalValue: string, newValue: string }>();
    const toasterId = useId("toaster");
    const { dispatchToast } = useToastController(toasterId);
    const [alerts, setAlerts] = useState<ICustomAlertProps[]>([]);
    const [signInError, setSignInError] = useState<string>();

    const { mutate } = useSWR<IUserWithEffectivePermissions>(msalContext.accounts.length > 0 && msalContext.accounts[0].localAccountId ? [`/api/me`, msalContext, "GET"] : null, fetcher, {
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        onError: (error) => {
            setSignInError(error);
        },
        onSuccess: (data) => {
            setSignInError(undefined);
            signedInUserContext.setSignedInUser(data);
        }
    });

    const onAcceptTerms = async () => {
        await fetcher(["/api/me/acceptTerms", msalContext, "POST", undefined, undefined])
            .then(() => {
                mutate();
                notify("Terms and conditions accepted", "success");
            })
    }

    const notify = (message: string, intent: ToastIntent) =>
        dispatchToast(
            <Toast>
                <ToastTitle>{message}</ToastTitle>
            </Toast>,
            { position: "top-end", intent, timeout: 5000 }
        );

    return (
        <AppContext.Provider value={{
            pageName,
            setPageName,
            notify,
            alerts,
            setAlerts
        }}>
            {signInError && (
                <div className={authenticatedContentStyles().signInError}>
                    <CustomAlert id="signInError" type={TaskStatus.Warning} text={signInError} />
                </div>
            )}
            {signedInUserContext.signedInUser &&
                <div className={authenticatedContentStyles().root}>
                    <Toaster toasterId={toasterId} />
                    <NavMenu />
                    <div className={authenticatedContentStyles().content}>
                        <AppAlerts
                            alerts={alerts}
                            setAlerts={setAlerts}
                        />
                        <BreadcrumbMenu />
                        <ErrorBoundary>
                            {signedInUserContext.signedInUser.acceptedTermsAndConditionsDate
                                ? <ContentRoutes />
                                : (<AcceptDialog
                                    onConfirm={() => onAcceptTerms()}
                                    title="Accept Terms and Conditions"
                                >
                                    <Text>Please confirm you accept the terms and conditions outlined at <Link href="https://cvdgroup.com/legal/" target="_blank">https://cvdgroup.com/legal/</Link></Text>
                                </AcceptDialog>)
                            }
                        </ErrorBoundary>
                    </div>
                </div>
            }
        </AppContext.Provider >
    );
}
