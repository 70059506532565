import React, { } from "react";
import ErrorBoundary from "./ErrorBoundary";
import { sharedHorizontalMediumGapFlexStyles } from "../styles/styles";
import { DrawerBody, DrawerFooter, DrawerHeader, DrawerHeaderTitle, OverlayDrawer } from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";
import { Button, Subtitle1 } from "@fluentui/react-components";

export interface ICustomDrawerProps {
    open: boolean;
    onDismiss: () => void;
    headerText: string;
    size?: "small" | "medium" | "large" | "full" | undefined;
    onSave?: () => void;
}

export const CustomDrawer: React.FunctionComponent<ICustomDrawerProps> = (props) => {

    const onOpenChange = (open: boolean) => {
        if (open) {
            props.onDismiss();
        }
    };

    return (
        <OverlayDrawer
            open={props.open}
            position="end"
            size={props.size}
            onOpenChange={(_, data) => onOpenChange(data.open)}
        >
            <DrawerHeader>
                <DrawerHeaderTitle
                    action={
                        <Button
                            appearance="subtle"
                            aria-label="Close"
                            icon={<Dismiss24Regular />}
                            onClick={() => props.onDismiss()}
                        />
                    }
                >
                    <Subtitle1>{props.headerText}</Subtitle1>
                </DrawerHeaderTitle>
            </DrawerHeader>
            <DrawerBody>
                <ErrorBoundary>
                    {props.children}
                </ErrorBoundary>
            </DrawerBody>
            <DrawerFooter>
                <div className={sharedHorizontalMediumGapFlexStyles().root}>
                    {props.onSave &&
                        (<Button
                            onClick={props.onSave}
                            appearance="primary"
                        >
                            Save
                        </Button>)
                    }
                    <Button
                        onClick={props.onDismiss}
                    >
                        Close
                    </Button>
                </div>
            </DrawerFooter>
        </OverlayDrawer>
    );
};
