import { z } from "zod";
import { IAuditChange } from "./IAuditChange";
import { IAuditType } from "./IAuditType";
import { baseCosmosItemSchema } from "./IBaseCosmosItem";
import { IOperationStatus } from "./IOperationStatus";
import { TaskStatus } from "./enums/TaskStatus";

export const auditSchema = baseCosmosItemSchema.extend({
    initiatedByUserId: z.string()
        .uuid(),
    date: z.string()
        .datetime(),
    description: z.string(),
    type: z.custom<IAuditType>(),
    status: z.nativeEnum(TaskStatus, { required_error: "Status is required" }),
    database: z.object({
        container: z.string(),
        entityId: z.string()
            .uuid()
            .optional(),
        entityName: z.string()
            .optional(),
        output: z.any()
            .optional(),
        changes: z.array(z.custom<IAuditChange>()),
    })
        .optional(),
    error: z.string()
        .optional(),
    operation: z.custom<IOperationStatus>()
        .optional(),
    parentId: z.string()
        .uuid()
        .optional()
})

export type IAudit = z.infer<typeof auditSchema>;

export interface IAuditExtended extends IAudit {
    initiatedByUserDisplayName: string;
}
