import { IMsalContext } from "@azure/msal-react";
import axios, { AxiosHeaders, Method } from "axios"
import { apiScopes } from "./auth";
import { InteractionStatus } from "@azure/msal-browser";

const baseUrl = process.env.REACT_APP_API_ROOT_URL;

async function getTokenForScopes(msalContext: IMsalContext, scopes: { scopes: string[] }): Promise<string | undefined> {
    if (msalContext && msalContext.accounts.length > 0) {
        return await msalContext.instance.acquireTokenSilent({ ...scopes })
            .then((response: { accessToken: string; }) => {
                return response.accessToken;
            }).catch((error: string | undefined) => {
                throw new Error(error);
            });
    }
}

export const fetcher = async (key: [path: string, msalContext: IMsalContext, method?: Method, params?: unknown, data?: unknown], options?: Readonly<{ arg: { method?: string, data?: unknown, params?: unknown }; }>) => {
    try {
        // Only continue if we have a valid msalContext that is not in progress
        if (key[1].inProgress !== InteractionStatus.None) {
            return;
        }
        // URL
        const url = `${baseUrl}${key[0]}`;
        // Headers
        const headers = new AxiosHeaders();
        // Get tokens before sending request
        await getTokenForScopes(key[1], { scopes: apiScopes })
            .then((token: string | undefined) => {
                if (token) {
                    headers.set("Authorization", `Bearer ${token}`);
                } else {
                    throw new Error("No token for API Scopes");
                }
            });
        // Send request
        const method = options?.arg?.method || key[2] || "GET";
        const timestamp = new Date().toISOString();
        console.log(`[${timestamp}] : Sending ${method} ${url}`);
        const res = await axios({
            method,
            url,
            data: options?.arg?.data || key[4] || {},
            headers,
            params: options?.arg?.params || key[3] || {}
        });
        return res.data;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
        console.error(error);
        throw error.response.data;
    }
}
