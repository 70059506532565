import { PhoneNumberAssignmentAction } from "@symity-hub/types"
import startCase from "lodash.startcase"
import { DateTime } from "luxon"

export const renderPhoneNumberWithExtension = (phoneNumber?: string, extensionNumber?: string): string => {
    return `${phoneNumber ? phoneNumber : ""}${extensionNumber ? " x" + extensionNumber : ""}`
}

export const renderPhoneNumberAssignmentText = (action: PhoneNumberAssignmentAction, phoneNumber: string, extensionNumber?: string, displayName?: string): string => {
    const actionText = action === PhoneNumberAssignmentAction.Assign ? "Assigned" : "Unassigned"
    return `${actionText} phone number ${renderPhoneNumberWithExtension(phoneNumber, extensionNumber)} to user ${displayName}`
}

export const generateDateFromIso = (isoDate: string) => {
    return DateTime.fromISO(isoDate).toLocaleString(DateTime.DATETIME_MED);
}

export const toTitleCase = (str: string | undefined) => {
    return startCase(str);
}