import React from "react";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import ErrorBoundary from "../components/ErrorBoundary";
import { AuthenticatedContent } from "./AuthenticatedContent";
import { CustomAlert } from "../components/CustomAlert";
import { makeStyles } from "@fluentui/react-components";

interface IMainViewProps {
}

const mainViewStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        flexGrow: 1
    }
});

export const MainView: React.FunctionComponent<IMainViewProps> = () => {

    return (
        <div className={mainViewStyles().root}>
            <ErrorBoundary>
                <AuthenticatedTemplate>
                    <AuthenticatedContent />
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <CustomAlert
                        text="Please sign in."
                        id="pleaseSignIn"
                    />
                </UnauthenticatedTemplate>
            </ErrorBoundary>
        </div>
    );
};
