import { z } from "zod";
import { PhoneNumberAssignmentAction } from "./enums/PhoneNumberAssignmentAction";
import { PhoneNumberType } from "./enums/PhoneNumberType";
import { operationTaskSchema } from "./IOperationTask";

export const phoneNumberAssignmentTaskSchema = operationTaskSchema.extend({
    userId: z.string()
        .uuid(),
    phoneNumber: z.string(),
    extensionNumber: z.string()
        .optional(),
    phoneNumberType: z.nativeEnum(PhoneNumberType),
    action: z.nativeEnum(PhoneNumberAssignmentAction)
})
    .strict();

export type IPhoneNumberAssignmentTask = z.infer<typeof phoneNumberAssignmentTaskSchema>;