import { z } from "zod";
import { operationTaskSchema } from "./IOperationTask";

export const batchPolicyAssignmentTaskSchema = operationTaskSchema.extend({
    userIds: z.array(
        z.string()
            .uuid()
    ),
    policyType: z.string(),
    policyName: z.string(),
    teamsOperationId: z.string()
        .uuid()
        .optional()
})
    .strict();

export type IBatchPolicyAssignmentTask = z.infer<typeof batchPolicyAssignmentTaskSchema>;