import React, { useState } from "react";
import { Alert24Regular } from "@fluentui/react-icons";
import { NotificationUserPanel } from "./NotificationUserPanel";
import { appHeaderStyles } from "./AppHeader";

interface INotificationsProps {
}

export const Notifications: React.FunctionComponent<INotificationsProps> = () => {
    const [open, setOpen] = useState(false);
    return (
        <div>
            <div
                className={appHeaderStyles().menuItem}
                onClick={() => setOpen(!open)}
            >
                <Alert24Regular />
            </div>
            {open && (
                <NotificationUserPanel
                    open={open}
                    setOpen={setOpen}
                />
            )}
        </div>
    )
}
